import { MEDIA_TYPE } from "@/shared/media";
import { useField } from "formik";
import { useMemo } from "react";

export interface CurrentArtwork {
  [fieldName: string]: string;
  mediaType: string;
}

export default function useArtworkField(fieldName: string): CurrentArtwork {
  const [{ value: image }] = useField("image");
  const [{ value: thumbnail }] = useField("thumbnail");
  const [{ value: mediaType }, ,] = useField("mediaType");

  return useMemo(() => {
    if (fieldName === "artworkFile") {
      return { [fieldName]: image, mediaType };
    }

    return {
      [fieldName]: thumbnail,
      mediaType: MEDIA_TYPE.IMAGE,
    };
  }, [fieldName, image, mediaType, thumbnail]);
}
