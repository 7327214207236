import { MEDIA_TYPE } from "@/shared/media";
import { ImagePlaceholder } from "../Image/types";
import { ImageArtwork } from "./ImageArtwork";
import { ModelArtwork } from "./ModelArtwork";
import { VideoArtwork } from "./VideoArtwork";

export interface Props {
  media: any;
  placeholder?: ImagePlaceholder;
  type?: string;
  width?: number;
  height?: number;
  className?: string;
  fullScreenEnabled?: boolean;
}

export default function ArtworkMedia({ media, type, ...props }: Props) {
  return (
    <>
      {type === MEDIA_TYPE.IMAGE || type === MEDIA_TYPE.GIF ? (
        <ImageArtwork image={media} {...props} />
      ) : type === MEDIA_TYPE.VIDEO ? (
        <VideoArtwork video={media} {...props} />
      ) : type === MEDIA_TYPE.MODEL ? (
        <ModelArtwork model={media} {...props} />
      ) : null}
    </>
  );
}
