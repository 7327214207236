import Script from "next/script";
import { useState, useCallback, useEffect } from "react";

export interface Props {
  model: string;
  className?: string;
}

export type ViewerRefType = {
  scale: string;
  updateFraming: () => Promise<void>;
  addEventListener: (event: string, callback: () => void) => void;
  dismissPoster: () => void;
  getDimensions: () => { x: number; y: number; z: number };
};

export default function ModelViewer({ model, className }: Props) {
  const [viewerRef, setViewerRef] = useState<ViewerRefType | null>(null);

  const isModelTooLarge = useCallback(() => {
    if (viewerRef) {
      const x = viewerRef.getDimensions().x;
      return x > 25;
    }
    return false;
  }, [viewerRef]);

  const scaleDown = useCallback(
    async (factor: number) => {
      if (viewerRef) {
        viewerRef.scale = `${factor} ${factor} ${factor}`;
        await viewerRef.updateFraming();
      }
    },
    [viewerRef],
  );

  const addLoadListener = useCallback(() => {
    if (viewerRef) {
      viewerRef.addEventListener("load", () => {
        if (isModelTooLarge()) {
          scaleDown(0.5);
        }
        viewerRef.dismissPoster();
      });
    }
  }, [viewerRef, isModelTooLarge, scaleDown]);

  useEffect(() => {
    const modelRef = document.querySelector(
      "model-viewer#model",
    ) as unknown as ViewerRefType;

    setViewerRef(modelRef);

    if (viewerRef !== null) {
      addLoadListener();
    }
  }, [model, viewerRef, addLoadListener]);

  return (
    <>
      <Script
        src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"
        type="module"
        strategy="lazyOnload"
      />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <model-viewer
        id="model"
        src={model}
        ar
        ar-modes="webxr scene-viewer"
        auto-rotate
        shadow-intensity="0.2"
        camera-controls
        bounds="tight"
        className={className}
      >
        <div slot="progress-bar" />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
      </model-viewer>
    </>
  );
}
