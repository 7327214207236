import { Tooltip } from "../Tooltip";
import { DropdownInput } from "../DropdownInput";
import InfoIcon from "@/assets/icons/info.svg";
import { twMerge } from "tailwind-merge";
import { useField } from "formik";
import { useEffect } from "react";
import { Props as DropdownInputBaseProps } from "../DropdownInputBase/index";
import { MultipleSelectDropdownInput } from "../MultiSelectDropdownInput";

export interface Props extends DropdownInputBaseProps<string> {
  name: string;
  label?: string;
  tooltipText?: string;
  className?: string;
  disabled?: boolean;
  containerClassName?: string;
  allThemesWithWhiteBackground?: boolean;
  tooltipIconClassName?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  dropdownLabel?: string | JSX.Element;
}

const FormDropdown = ({
  name,
  label,
  tooltipText,
  disabled,
  allThemesWithWhiteBackground,
  className,
  containerClassName,
  tooltipIconClassName,
  wrapperClassName,
  labelClassName,
  isMultiple,
  dropdownLabel,
  options,
  ...props
}: Props) => {
  const [field, { error, touched }, { setValue }] = useField(name);

  const isError = touched && error;

  useEffect(() => {
    setValue(field.value);
  }, [field.value, setValue]);

  return (
    <div className={twMerge("mb-[22px] w-full", wrapperClassName)}>
      <div className="relative">
        {tooltipText && (
          <Tooltip
            text={tooltipText}
            className="lg:hidden absolute top-0 right-4"
            tooltipClassName="w-44"
            position={["left", "top"]}
          >
            <InfoIcon className="w-[18px] h-[18px] fill-secondary" />
          </Tooltip>
        )}
      </div>
      {label && (
        <label
          className={twMerge(
            "text-sm font-light tracking-tight px-4",
            allThemesWithWhiteBackground ? "text-endemicGray" : "text-primary",
            labelClassName,
          )}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div className={twMerge("flex items-center my-3", containerClassName)}>
        <div className="w-full text-start">
          {isMultiple ? (
            <MultipleSelectDropdownInput
              {...props}
              disabled={disabled}
              selectedOption={field.value}
              setSelectedOption={setValue}
              label={
                <>
                  <span className="font-light mr-1">{dropdownLabel}</span>
                  {field.value?.length > 1
                    ? `${field.value.filter((opt: string) => opt !== "selectAll").length} selected`
                    : options?.find((o) => o.value === field.value[0])?.label}
                </>
              }
              className={twMerge(className, isError ? "border-red" : "")}
              options={options}
            />
          ) : (
            <DropdownInput
              {...props}
              label={dropdownLabel}
              disabled={disabled}
              selectedOption={field.value}
              setSelectedOption={setValue}
              className={twMerge(className, isError ? "border-red" : "")}
              options={options}
            />
          )}
          {isError ? (
            <span className="text-red endemic:text-white font-semibold text-xs px-4 h-[14px]">
              {error}
            </span>
          ) : null}
        </div>
        {tooltipText && (
          <Tooltip
            text={tooltipText}
            tooltipClassName="w-44"
            className="hidden lg:inline"
            position={["center", "top"]}
          >
            <div className={twMerge("ml-10", tooltipIconClassName)}>
              <InfoIcon
                className={twMerge(
                  "w-[18px] h-[18px] fill-secondary",
                  allThemesWithWhiteBackground ? "" : "endemic:fill-primary",
                  tooltipIconClassName,
                )}
              />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default FormDropdown;
