import FolderIcon from "@/assets/icons/folder.svg";
import ChangeCoverIcon from "@/assets/icons/background.svg";
import { UploadFile } from "@/app/(components)/Basic/UploadFile";
import { useField } from "formik";
import { twMerge } from "tailwind-merge";
import { validateFile } from "@/shared/files";
import { useMemo } from "react";

export interface Props {
  name: string;
  text: string;
  acceptTypes: string;
  maxFileSize: number;
  setIsDraggingOver: (arg: boolean) => void;
  setFileLocalPath: (localPath: string) => void;
}

export default function ArtworkUploadReady({
  name,
  text,
  acceptTypes,
  maxFileSize,
  setIsDraggingOver,
  setFileLocalPath,
}: Props) {
  const [, { error, touched }, { setError, setValue }] = useField(name);

  const isError = useMemo(() => touched && !!error, [error, touched]);

  const clearError = () => {
    setError(undefined);
  };

  const handleFileDrop = (e: React.DragEvent) => {
    setIsDraggingOver(false);
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    const isValidFile = validateFile(file, 10, acceptTypes, setError);
    if (!isValidFile) {
      return;
    }

    setFileLocalPath(URL.createObjectURL(file));
    setValue(file);
    clearError();
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  return (
    <div
      onDrop={handleFileDrop}
      onDragOver={handleDragOver}
      onDragLeave={() => setIsDraggingOver(false)}
      className={twMerge("flex flex-col items-center")}
    >
      <ChangeCoverIcon className="w-7 h-7 night:fill-white endemic:fill-white" />
      <div
        className={twMerge(
          "text-body-3 mt-6 font-primary",
          isError ? "text-red endemic:text-yellow" : "",
        )}
      >
        {!isError ? "Browse and select a file or drag and drop here" : error}
      </div>
      <div className="text-grey text-body-4 mt-2">
        {!isError ? text : error}
      </div>
      <UploadFile
        fieldName={name}
        startIcon={<FolderIcon className="fill-primary endemic:fill-invert" />}
        title="Browse..."
        native={false}
        className="mt-6"
        maxFileSize={maxFileSize}
        accept={acceptTypes}
        titleClassName="text-primary endemic:text-invert inline"
        setLocalPath={setFileLocalPath}
      />
    </div>
  );
}
