import TrashIcon from "@/assets/icons/trash.svg";
import CheckmarkIcon from "@/assets/icons/plain-checkmark.svg";
import { Button } from "@/app/(components)/Basic/Button";
import { useField } from "formik";
import { ArtworkMedia } from "@/app/(components)/Basic/ArtworkMedia";
import { getMediaType } from "@/shared/media";
import { useMemo } from "react";

export interface Props {
  title: string;
  name: string;
  mediaUrl: string | undefined;
  mediaType: string | undefined;
  onRemove: () => void;
}

const ArtworkUploadAccepted = ({
  title,
  name,
  mediaUrl,
  mediaType,
  onRemove,
}: Props) => {
  const [{ value: file }, , { setValue }] = useField(name);

  const [, , { setValue: setMediaType }] = useField("mediaType");

  const mimeType = useMemo(() => {
    if (!file && !mediaType) return;

    return mediaType || getMediaType(file.name);
  }, [file, mediaType]);

  return (
    <div className="flex flex-col sm:flex-row justify-center items-center gap-x-12 gap-y-2">
      <ArtworkMedia
        media={mediaUrl}
        type={mimeType}
        width={200}
        height={200}
        className="max-w-[200px] max-h-[250px]"
      />
      <div className="flex flex-col justify-center items-center gap-y-10">
        <CheckmarkIcon className="light:fill-green fill-white" />
        <div className="flex flex-col justify-center items-center gap-y-2">
          <div className="text-body-3 text-center">{title}</div>
          <div className="text-body-4 text-secondary">
            {file?.name.length > 30
              ? `${file?.name.slice(0, 20)}.....${file.name.slice(-10)}`
              : file?.name}
          </div>
        </div>
        <Button
          onClick={() => {
            setMediaType(undefined);
            setValue(undefined);
            onRemove();
          }}
          startIcon={<TrashIcon className="fill-black night:fill-white w-6" />}
        >
          Remove
        </Button>
      </div>
    </div>
  );
};

export default ArtworkUploadAccepted;
