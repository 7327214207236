import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
}

const LineSkeleton = ({ className }: Props) => {
  return (
    <div role="status" className="max-w-sm animate-pulse">
      <div
        className={twMerge(
          "h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4",
          className,
        )}
      ></div>
    </div>
  );
};

export default LineSkeleton;
