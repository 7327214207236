import { Tooltip } from "@/app/(components)/Basic/Tooltip";
import { checkFileExtension, PLACEHOLDER_REQUIRED_EXT } from "@/shared/media";
import { useField } from "formik";
import { useState, useEffect, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import InfoIcon from "@/assets/icons/info.svg";
import { ArtworkUploadAccepted } from "../ArtworkUploadAccepted";
import { ArtworkUploadReady } from "../ArtworkUploadReady";
import useArtworkField from "./hooks/useArtwork";
import {
  ARTWORK_CURRENT_MESSAGE,
  ARTWORK_UPLOAD_SUCCESS_MESSAGE,
} from "./constants";
import CloseIcon from "@/assets/icons/close-thin.svg";

export interface Props {
  name: string;
  text: string;
  label?: string;
  acceptTypes: string;
  maxFileSize: number;
  tooltipText?: string;
  removeTooltipOnDesktop?: boolean;
  allThemesWithWhiteBackground?: boolean;
  tooltipIconClassName?: string;
  setThumbnailUpload?: (arg: boolean) => void;
  onClose?: () => void;
  onRemove?: () => void;
}

export default function ArtworkUpload({
  name,
  text,
  label,
  acceptTypes,
  maxFileSize,
  tooltipText,
  removeTooltipOnDesktop,
  allThemesWithWhiteBackground,
  tooltipIconClassName,
  setThumbnailUpload,
  onClose,
  onRemove,
}: Props) {
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);

  const [fileLocalPath, setFileLocalPath] = useState<string>();

  const [isAccepted, setIsAccepted] = useState(false);

  const [{ value: file }, { error, touched }, { setError, setTouched }] =
    useField(name);

  const { [name]: artwork, mediaType } = useArtworkField(name);

  const title = useMemo(() => {
    if (file) return ARTWORK_UPLOAD_SUCCESS_MESSAGE;

    return ARTWORK_CURRENT_MESSAGE;
  }, [file]);

  useEffect(() => {
    if (!artwork) return;

    setFileLocalPath(artwork);
    setIsAccepted(true);
  }, [artwork, name]);

  useEffect(() => {
    if (!file || !setThumbnailUpload) return;

    const isPlaceholderRequired = checkFileExtension(
      file.name,
      PLACEHOLDER_REQUIRED_EXT,
    );

    if (isPlaceholderRequired) {
      setThumbnailUpload(true);
    }
  }, [file, setThumbnailUpload]);

  useEffect(() => {
    if (!file?.imageUrl) return;

    setIsAccepted(true);
    setFileLocalPath(file.imageUrl);
  }, [file]);

  useEffect(() => {
    if (file || artwork) return;

    setIsAccepted(false);
  }, [artwork, file]);

  return (
    <>
      <div className="relative">
        {tooltipText && (
          <Tooltip
            text={tooltipText}
            className="lg:hidden absolute top-0 right-4"
            tooltipClassName="w-44"
            position={["left", "top"]}
          >
            <InfoIcon className="w-[18px] h-[18px] fill-secondary" />
          </Tooltip>
        )}
      </div>
      {label && (
        <label
          className={twMerge(
            "text-sm font-light tracking-tight px-4",
            allThemesWithWhiteBackground ? "text-endemicGray" : "text-primary",
          )}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div className="flex flex-row items-center">
        <div
          className={twMerge(
            "w-full mt-2",
            isDraggingOver
              ? "border-emerald-400 border-4 border-solid rounded-2xl p-1"
              : "",
          )}
        >
          <div
            className={twMerge(
              "flex flex-col items-center justify-center w-full h-full lg:h-[300px] p-5 rounded-2xl border-2 border-dashed border-endemicGray-300 night:border-endemicGray-900 relative",
              touched && !!error ? "border-red endemic:border-yellow" : "",
              isDraggingOver ? "bg-emerald-100/50" : "",
            )}
          >
            {onClose && (
              <CloseIcon
                className="w-3 h-3 fill-primary absolute lg:top-5 lg:right-5 top-2 right-2  cursor-pointer"
                onClick={onClose}
              />
            )}
            {isAccepted ? (
              <ArtworkUploadAccepted
                title={title}
                name={name}
                mediaUrl={fileLocalPath}
                mediaType={mediaType}
                onRemove={() => {
                  if (setThumbnailUpload) {
                    setThumbnailUpload(false);
                  }

                  if (onRemove) {
                    onRemove();
                  }
                  setIsAccepted(false);
                  setError(undefined);
                  setTouched(false);
                }}
              />
            ) : (
              <ArtworkUploadReady
                name={name}
                text={text}
                acceptTypes={acceptTypes}
                maxFileSize={maxFileSize}
                setIsDraggingOver={setIsDraggingOver}
                setFileLocalPath={(localPath) => {
                  setFileLocalPath(localPath);
                  setIsAccepted(true);
                }}
              />
            )}
          </div>
          {error && touched ? (
            <span className="text-red endemic:text-yellow font-semibold text-xs px-4 h-[14px]">
              {error}
            </span>
          ) : null}
        </div>
        {!removeTooltipOnDesktop && tooltipText && (
          <Tooltip
            text={tooltipText}
            tooltipClassName="transform -translate-y-[calc(100%+35px)] -right-[20px] lg:-right-[140px]"
            className={twMerge("hidden lg:inline")}
            position={["center", "top"]}
          >
            <div className={twMerge("ml-10", tooltipIconClassName)}>
              <InfoIcon
                className={twMerge(
                  "w-[18px] h-[18px] fill-secondary flex",
                  allThemesWithWhiteBackground ? "" : "endemic:fill-primary",
                )}
              />
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
}
