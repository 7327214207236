import { twMerge } from "tailwind-merge";
import React, { useRef } from "react";
import { EndemicImage } from "../../Image";
import { ImagePlaceholder } from "../../Image/types";

export interface Props {
  image: string;
  placeholder?: ImagePlaceholder;
  width?: number;
  height?: number;
  className?: string;
  fullScreenEnabled?: boolean;
}

export default function ImageArtwork({
  image,
  placeholder,
  width,
  height,
  className,
  fullScreenEnabled,
}: Props) {
  const imageWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={twMerge(
        "w-auto h-auto",
        fullScreenEnabled && "relative flex items-center justify-center",
      )}
      ref={imageWrapperRef}
    >
      <EndemicImage
        alt=""
        src={image}
        placeholder={placeholder}
        width={width}
        height={height}
        displayDefaultPlaceholder
        className={twMerge(
          "object-contain",
          fullScreenEnabled && "lg:max-h-full",
          className,
        )}
        fill={!width || !height}
        aspectRatio={!!width && !!height}
      />
    </div>
  );
}
