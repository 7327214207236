"use client";
import { useMemo } from "react";
import { DropdownInputBase } from "../DropdownInputBase";
import { PropsMultiple } from "../DropdownInputBase/DropdownInputBase";
import { OptionValue } from "../DropdownInputBase/types";

function MultipleSelectDropdownInput<T extends OptionValue>({
  selectedOption,
  setSelectedOption,
  options,
  ...props
}: PropsMultiple<T>) {
  const multipleSelected = useMemo(() => {
    return (!!selectedOption ? [selectedOption] : []).flat();
  }, [selectedOption]);

  const label = useMemo(() => {
    if (!!multipleSelected.length) {
      return `${multipleSelected.length} selected`;
    }
  }, [multipleSelected]);

  const isSingleOptionClickedWhileAllIsSelected = (option: any) => {
    return (
      options &&
      option.length === options?.length - 1 &&
      option.includes("selectAll")
    );
  };

  const onFilterChange = (option: T[]) => {
    if (selectedOption?.includes("selectAll" as T)) {
      if (isSingleOptionClickedWhileAllIsSelected(option)) {
        setSelectedOption(option.filter((opt) => opt !== "selectAll"));
      } else {
        // Deselect all
        setSelectedOption([]);
      }
      return;
    }

    // Select all
    if (option.includes("selectAll" as T)) {
      options && setSelectedOption(options?.map((op: any) => op.value));

      return;
    }

    // Single select
    setSelectedOption(option);
  };

  return (
    <DropdownInputBase
      selectedOption={multipleSelected}
      setSelectedOption={onFilterChange}
      options={options}
      label={label}
      isMultiple
      {...props}
    />
  );
}

export default MultipleSelectDropdownInput;
