import { setLocalStorage } from "@/app/(components)/ThirdParty/helpers";
import { SEARCH_IMAGE_KEY } from "@/app/(components)/Application/Search/constants";

const getSizeInMb = (sizeInBytes: number) => {
  return (sizeInBytes / 1024 / 1024) * 1.048576;
};

const getSizeInKb = (sizeInBytes: number) => {
  return (sizeInBytes / 1024) * 1.048576;
};

const getFileExtension = (filename: string) => {
  const pattern = /\.[0-9a-z]+$/i;

  return filename.match(pattern)?.[0] || "";
};

const isValidMediaType = (file: File | undefined, acceptTypes: string) => {
  if (!file) return false;

  const ext = getFileExtension(file.name);

  if (acceptTypes.includes(file.type) || acceptTypes.includes(ext)) {
    return true;
  }

  return false;
};

const validateFile = (
  file: File | undefined,
  maxFileSize: number,
  acceptTypes: string,
  setError: (err: string) => void,
) => {
  if (!file) return false;

  if (getSizeInMb(file.size) > maxFileSize) {
    setError(`File exceeds size limit of ${maxFileSize} MB.`);
    return false;
  }

  if (!isValidMediaType(file, acceptTypes)) {
    setError(`Only ${acceptTypes} types are supported.`);
    return false;
  }

  return true;
};

const validateFileWithError = (
  file: File | undefined,
  maxFileSize: number,
  acceptTypes: string,
  setError: (err: string) => void,
) => {
  if (!file) return { validate: false, errorMsg: null };

  let errMsg;

  if (getSizeInMb(file.size) > maxFileSize) {
    errMsg = `File exceeds size limit of ${maxFileSize} MB.`;
    setError(errMsg);
    return {
      validate: false,
      errorMsg: errMsg,
    };
  }

  if (!isValidMediaType(file, acceptTypes)) {
    errMsg = `Only ${acceptTypes} types are supported.`;
    setError(errMsg);
    return {
      validate: false,
      errorMsg: errMsg,
    };
  }

  return { validate: true, errorMsg: null };
};

const readAndStoreFileToLocalStorage = async (image: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result;

      const imageLoaded = {
        name: image.name,
        type: image.type,
        size: image.size,
        imageUrl: base64Image,
      };

      resolve(setLocalStorage(SEARCH_IMAGE_KEY, imageLoaded));
    };

    reader.readAsDataURL(image);
  });
};

export {
  getSizeInMb,
  validateFile,
  getSizeInKb,
  validateFileWithError,
  readAndStoreFileToLocalStorage,
};
