import { ModelViewer } from "../../ModelViewer";

export interface Props {
  model: any;
  className?: string;
}

export default function ModelArtwork({ model, className }: Props) {
  return (
    <div>
      <ModelViewer model={model} className={className} />
    </div>
  );
}
