import { validateFile } from "@/shared/files";
import { useField } from "formik";
import { ChangeEvent, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { Button } from "../Button";

export interface Props {
  fieldName: string;
  title?: string | undefined | null;
  startIcon: React.ReactNode;
  className: string;
  titleClassName?: string;
  accept: string;
  maxFileSize: number;
  setLocalPath: (localPath: string) => void;
  native?: boolean;
  disabled?: boolean;
}

export function UploadFile({
  fieldName,
  title,
  startIcon,
  className,
  titleClassName,
  setLocalPath,
  accept,
  maxFileSize,
  native = true,
  disabled,
}: Props) {
  const [, , { setValue, setError, setTouched }] = useField(fieldName);

  const inputRef = useRef<HTMLInputElement>(null);

  const clearError = () => {
    setError(undefined);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTouched(true, false);
    const file = event.target.files?.[0];
    const isFileValid = validateFile(file, maxFileSize, accept, setError);

    if (!isFileValid) {
      event.target.value = "";
      return;
    }

    if (!file) return;

    setLocalPath(URL.createObjectURL(file));
    setValue(file);
    clearError();
  };

  return (
    <Button
      onClick={() => inputRef.current?.click()}
      className={className}
      type="button"
      native={native}
      startIcon={startIcon}
      disabled={disabled}
    >
      <p
        className={twMerge(
          "hidden lg:inline text-white text-base tracking-tight ml-2",
          titleClassName,
        )}
      >
        {title}
      </p>
      <input
        type="file"
        ref={inputRef}
        onChange={(e) => handleFileChange(e)}
        className="hidden"
        accept={accept}
      />
    </Button>
  );
}
