import { useRef } from "react";

export interface Props {
  video: string;
  className?: string;
}

export default function VideoArtwork({ video, className }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleDoubleClick = () => {
    videoRef.current?.requestFullscreen();
  };

  return (
    <div className={className}>
      <video
        ref={videoRef}
        onDoubleClick={handleDoubleClick}
        className="w-full h-auto"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
}
